<template>
  <div style="width: 65%; justify-content: center; margin: 0 auto;">
    <md-card>
      <md-card-header>
        <div class="md-layout md-size-90 md-alignment-center">
          <p class="md-headline">{{ $t('terms.termsOfUse') }} - {{ $t('terms.amfInc') }}
          </p>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            {{ $t('terms.followingConditions') }}
          </div>
        </div>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            <p class="md-subheading bold">
              {{ $t('terms.privacyPolicy') }}
            </p>
            <p class="">
              Please preview our
              <router-link v-if="authType === 'USER'" tag="div" :to="{ name: 'policy' }">
                {{ $t('terms.privacyPolicy') }}
              </router-link>
              which also governs your visit to wibki.com, to understand our practices.
            <p class="md-subheading bold">
              {{ $t('terms.licensing') }}
            </p>
            <p>
              {{ $t('terms.licensingTxt') }}
            </p>
            <p class="md-subheading bold">Wibki {{ $t('terms.termsOfUse') }}
            </p>
            <ul>
              <li>
                <p>
                  {{ $t('terms.terms1') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms2') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms3') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms4') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms1') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms5') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms6') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms7') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms8') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms9') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms10') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms11') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms12') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms13') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms14') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms15') }}
                </p>
              </li>
              <li>
                <p>
                  {{ $t('terms.terms16') }}
                </p>
              </li>
            </ul>
            <p class="md-subheading bold">
              {{ $t('terms.disclaimerWarrantyLiability') }}
            </p>
            <p>
              {{ $t('terms.discWarrLiTxt1') }}
            </p>
            <p>
              {{ $t('terms.discWarrLiTxt2') }}
            </p>
            <p>
              {{ $t('terms.discWarrLiTxt3') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('terms.other') }}
            </p>
            <p>
              {{ $t('terms.otherTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('terms.yourFeedback') }}
            </p>
            <p>
              {{ $t('terms.yourInput') }}
            </p>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'Terms',
};
</script>
